import Swiper, {Autoplay, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let testimonialsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr');
testimonialsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      breakpoints: {
        640: {
          spaceBetween: 18,
        },
        992: {
          spaceBetween: 128,
        }
      },
    });

    swiperObserver(swiper);
  }
});
