const faqSection = document.querySelector('.faq-section');
if (faqSection) {
  const faqItemsArr = document.querySelectorAll('.faq-section__item');

  const firstButtonIcon = faqSection.querySelector(
    '.faq-section__item-button i'
  );
  if (firstButtonIcon) {
    firstButtonIcon.classList.remove('icon-plus');
    firstButtonIcon.classList.add('icon-minus');
  }

  faqItemsArr.forEach((item) => {
    item.addEventListener('click', () => {
      const icon = item.querySelector('.faq-section__item-button i');
      const content = item.querySelector('.faq-section__item-bottom-content');
      const hiddenEl = item.querySelector('.faq-section__item-bottom');
      const contHeight = content.clientHeight;

      if (item.classList.contains('active')) {
        item.classList.remove('active');
        icon.classList.add('icon-plus');
        icon.classList.remove('icon-minus');
      } else {
        openItem();
      }

      function openItem() {
        for (let i = 0; i < faqItemsArr.length; i++) {
          const iconEl = faqItemsArr[i].querySelector(
            '.faq-section__item-button i'
          );
          faqItemsArr[i].classList.remove('active');
          iconEl.classList.add('icon-plus');
          iconEl.classList.remove('icon-minus');
        }

        item.classList.add('active');
        icon.classList.remove('icon-plus');
        icon.classList.add('icon-minus');
        hiddenEl.style.maxHeight = `${contHeight + 30}px`;
      }
    });
  });
}
