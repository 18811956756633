import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/swiperOnResize.utils.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';
import './components/who-we-are.component.js';
import './components/header.component.js';

import './libs/countUp.lib.js';
import './components/meta-select.component.js';

// Component //

import './components/marquee.component.js';
import './components/faq.component.js';
import './components/ethos.component.js';

// Swiper //

import './components/swiper-team.component.js';
import './components/swiper-our-expertise.component.js';
import './components/swiper-testimonials.component.js';
import './components/swiper-choose.component.js';
import './components/swiper-ethos.component.js';
import './components/swiper-reviews.component.js';
