import Swiper, { Autoplay, Navigation, Scrollbar } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let OurSwiperWr = document.querySelectorAll('.our-expertise-wr');
OurSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scroll = el.querySelector('.swiper-scrollbar');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scroll,
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      breakpoints: {
        // 420: {
        //   slidesPerView: 1.1,
        // },
        580: {
          spaceBetween: 16,
        },
        // 768: {
        //   slidesPerView: 2.1,
        // },
        // 950: {
        //   slidesPerView: 2.3,
        // },
        // 1024: {
        //   spaceBetween: 24,
        //   slidesPerView: 2.5,
        // },
        1200: {
          spaceBetween: 24,
        },
        // 1440: {
        //   spaceBetween: 24,
        //   slidesPerView: 2.44,
        // },
      },
    });

    swiperObserver(swiper);
  }
});
