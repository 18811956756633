const ethosCards = document.querySelectorAll('.ethos-card')

if(ethosCards.length) {
  ethosCards.forEach((card) => {
    const cardToggle = card.querySelector('.ethos-card__arrow');

    cardToggle.addEventListener('click', () => {
      if(card.classList.contains('active')) return

      ethosCards.forEach(c => c.classList.remove('active'))
      card.classList.add('active')
    })
  })
}