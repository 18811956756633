document.addEventListener('DOMContentLoaded', function() {
  const section = document?.querySelector('#whoWeSection');

  if(section) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          section.classList.add('who-we-section--visible');
        }
      });
    }, { threshold: 0.1 });
  
    observer.observe(section);
  }
});