import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let chooseSwiperWr = document.querySelectorAll('.choose-swiper-wr');
chooseSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 12,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: false,
      // },
      breakpoints: {
        768: {
          spaceBetween: 24,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
  // if (el) {
  //   let swiperEl = el.querySelector('.swiper');
  //   let nextEl = el.querySelector('.swiper-button-next');
  //   let prevEl = el.querySelector('.swiper-button-prev');
  //   let slidesCount = el.querySelectorAll('.swiper-slide').length;
  //   let wrapper = swiperEl.querySelector('.swiper-wrapper');

  //   let swiper = new Swiper(swiperEl, {
  //     modules: [Navigation],
  //     observer: true,
  //     observeParents: true,
  //     spaceBetween: 16,
  //     slidesPerView: 1,
  //     threshold: 10,
  //     navigation: {
  //       nextEl: nextEl,
  //       prevEl: prevEl,
  //     },
  //     /*autoplay: {
  //       delay: 4000,
  //       disableOnInteraction: false,
  //       pauseOnMouseEnter: window.innerWidth >= 640,
  //     },*/
  //     breakpoints: {
  //       420: {
  //         slidesPerView: 1.1,
  //       },
  //       580: {
  //         spaceBetween: 16,
  //         slidesPerView: 1.5,
  //       },
  //       768: {
  //         slidesPerView: 2,
  //       },
  //       950: {
  //         slidesPerView: 2.3,
  //       },
  //       1024: {
  //         spaceBetween: 24,
  //         slidesPerView: 2.5,
  //       },
  //       1200: {
  //         spaceBetween: 0,
  //         slidesPerView: 3.5,
  //       },
  //     },
  //   });

  //   // swiperObserver(swiper);
  // }
});
