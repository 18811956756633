import Swiper, { Navigation, Scrollbar , EffectCreative} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let reviewsSwiperWr = document.querySelectorAll('.reviews-swiper-wr');
reviewsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, EffectCreative],
      observer: true, 
      observeParents: true,
      spaceBetween: 0,
      slidesPerView: 1,
      threshold: 10,
      effect: 'creative',
      direction: 'vertical',
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
          effect: 'scale',
          scale: 0.6,
        },
        next: {
          /*translate: ['calc(100% - 20px)', 0, 0],*/
          translate: [0, 'calc(19% - 20px)', 0],
          opacity: 1,
          effect: 'scale',
          scale: 0.79,
        },
      },


      // autoplay: {
      //   // delay: 5000,
      //   // disableOnInteraction: false,
      //   // pauseOnMouseEnter: false,
      // },
      breakpoints: {},
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
  }
});
